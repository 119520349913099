import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import Image from 'next/image';
import Link from 'next/link';
import Router from 'next/router';
import { infoActions, userActions } from '@redux/actions';
import { Button, ClickAwayListener } from '@material-ui/core';
import {
  whiteLogo,
  grayLogo,
  newLogo,
  china,
  indonesia,
  portugal,
  russia,
  uae,
  unitedkingdom,
  spain,
  korea,
  german,
  italy,
  dolar,
  euro,
  franc,
  pound,
  yen,
  binanceAndCULogos,
  mintoAndCULogos,
} from '@assets/img';
import { Trans } from '@i18n';
import { localeSubpaths } from '../../locales/languagesData';
import {
  isDashboard,
  isLanding,
  isPagePath,
  languageReturner,
} from '../../helpers';
import { changeLanguage } from '../../locales/i18n';
import { userService } from '../../services';

if (process.env.NODE_ENV === 'development') {
  require('./Header.scss');
  require('./mobile/index.scss');
}

const newMenu = (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 8H13" stroke="white" strokeWidth="2" />
    <path d="M1 15H21" stroke="white" strokeWidth="2" />
    <path d="M1 1H21" stroke="white" strokeWidth="2" />
  </svg>
);

type State = any;
class HeaderNew extends Component<any, State> {
  aboutRef: any;

  baseCurrency: any;

  baseMoneyRef: any;

  langRef: any;

  menuRef: any;

  moreRef: any;

  productsRef: any;

  transparentHeader: any;

  profileRef: any;

  userLogged: any;

  constructor(props: {}) {
    super(props);
    this.state = {
      openedLanguageMenu: false,
      openedProductsMenu: false,
      openedAboutMenu: false,
      openedProfileMenu: false,
      openedMoreMenu: false,
      landingLinksName: [
        'joint-participation-binance',
        'opportunities',
        'news',
        'aboutCryptoUniverse',
      ],
      hiddenLinksInMoreLink: [],
      hiddenLinksInMoreLinkExpanded: [],
      menuWidth: 0,
      childNodesWidth: 0,
      childNodesEachWidth: {},
    };
    this.profileRef = React.createRef();
    this.langRef = React.createRef();
    this.transparentHeader = React.createRef();
    this.baseMoneyRef = React.createRef();
    this.productsRef = React.createRef();
    this.aboutRef = React.createRef();
    this.menuRef = React.createRef();
    this.moreRef = React.createRef();
    const cookies = new Cookies();
    this.userLogged = cookies.get('user_logged');
    this.baseCurrency = cookies.get('base_currency');
    if (this.baseCurrency === undefined) {
      this.baseCurrency = 'US Dollar';
      cookies.set('base_currency', 'US Dollar', { path: '/' });
    }
    this.redirectsIfLoged();
  }

  onScroll = () => {
    const { location } = this.props;

    if (this.transparentHeader.current) {
      const { scrollY } = window; // Don't get confused by what's scrolling - It's not the window

      let scrollNeedle = 0;

      const classStr = this.transparentHeader.current.className;
      if (
        scrollY > scrollNeedle
        && classStr.includes('Header--transparent')
        && !classStr.includes('Header--white')
      ) {
        this.transparentHeader.current.className = `${classStr.replace(
          'Header--transparent',
          '',
        )}Header--white`;
      }
      if (
        scrollY <= scrollNeedle
        && !classStr.includes('Header--transparent')
        && classStr.includes('Header--white')
      ) {
        this.transparentHeader.current.className = `${classStr.replace(
          'Header--white',
          '',
        )}Header--transparent`;
      }
    }
  };

  componentDidMount() {
    const { location } = this.props;
    // window.addEventListener('scroll', this.onScroll);
    setTimeout(() => {
      this.setMenuEachLinkWidth();
      if (isLanding(location)) {
        this.hideInMoreLink();
      }
    }, 0);
  }

  componentDidUpdate(prevProps: {}, prevState: State) {
    const { windowWidth, location } = this.props;
    const { hiddenLinksInMoreLink } = this.state;
    if ((prevProps as any).windowWidth !== windowWidth) {
      if (isLanding(location)) {
        setTimeout(() => {
          this.hideInMoreLink();
        }, 0);
      }
    }
    if (prevState.hiddenLinksInMoreLink !== hiddenLinksInMoreLink) {
      this.expandSubMenu(hiddenLinksInMoreLink);
    }
  }

  expandSubMenu = (hiddenLinksInMoreLink: any) => {
    const getLinks = this.getLinks();
    const expandedLinks: any = [];
    hiddenLinksInMoreLink.map((link: any) => {
      if (getLinks[link].subMenu !== undefined) {
        expandedLinks.push(...getLinks[link].subMenu.links);
      } else {
        expandedLinks.push(link);
      }
    });
    this.setState({
      ...this.state,
      hiddenLinksInMoreLinkExpanded: expandedLinks,
    });
  };

  setMenuEachLinkWidth = () => {
    const menu = this.menuRef.current;
    if (menu !== null) {
      const { childNodes } = menu;
      const childNodesEachWidth: { [index: string]: any } = {};
      let findMoreLink = false;
      childNodes.forEach((node: any) => {
        if (node.dataset.name === 'more') findMoreLink = true;
        if (!findMoreLink) { childNodesEachWidth[node.dataset.name] = node.offsetWidth; }
      });
      this.setState({
        ...this.state,
        childNodesEachWidth,
      });
    }
  };

  hideInMoreLink = () => {
    const { childNodesEachWidth, hiddenLinksInMoreLink, landingLinksName } = this.state;
    const { windowWidth } = this.props;
    const menu = this.menuRef.current;
    const menuWidth = menu.offsetWidth;
    const { childNodes } = menu;
    let moreWidth = this.moreRef.current ? this.moreRef.current.offsetWidth : 0;
    let childNodesWidth = 0;
    childNodes.forEach((node: any) => {
      childNodesWidth += node.offsetWidth;
    });
    let popped: any = [];
    let added: any = [];
    let newLandingLinksName = [...landingLinksName];
    let newHiddenLinksInMoreLink = [...hiddenLinksInMoreLink];
    let moreWidthIfHidden = popped.length || hiddenLinksInMoreLink.length ? 0 : moreWidth;
    const needToReduceLink = () => {
      moreWidthIfHidden = popped.length || hiddenLinksInMoreLink.length ? 0 : moreWidth;
      const linksWidth = childNodesWidth - moreWidthIfHidden;
      let poppedLinksWidth = 0;
      if (popped.length) {
        popped.map(
          (item: string) => (poppedLinksWidth += childNodesEachWidth[item]),
        );
      }
      if (popped.length || hiddenLinksInMoreLink.length) { return menuWidth <= linksWidth - poppedLinksWidth; }
      return menuWidth <= linksWidth;
    };
    const needToAddLink = () => {
      if (newHiddenLinksInMoreLink.length === 0) return false;
      let addedLinksWidth = 0;
      if (added.length) {
        added.map(
          (item: string) => (addedLinksWidth += childNodesEachWidth[item]),
        );
      }
      const firstHiddenLink = newHiddenLinksInMoreLink[0];
      const firstHiddenLinkWidth = childNodesEachWidth[firstHiddenLink];
      const minusMoreLinkWidth = newHiddenLinksInMoreLink.length === 1 ? moreWidth : 0;
      return (
        menuWidth
        > childNodesWidth
          + addedLinksWidth
          - minusMoreLinkWidth
          + firstHiddenLinkWidth
      );
    };
    const addLink = () => {
      if (newHiddenLinksInMoreLink.length) {
        const addedLinkName = newHiddenLinksInMoreLink.shift();
        added.push(addedLinkName);
      }
    };
    const reduceLink = () => {
      if (newLandingLinksName.length) {
        const reducedLinkName = newLandingLinksName.pop();
        popped.unshift(reducedLinkName);
      }
    };
    if (windowWidth > 1024) {
      if (menuWidth <= childNodesWidth - moreWidthIfHidden) {
        let loopCount = 0;
        while (needToReduceLink() && loopCount < 10) {
          loopCount += 1;
          reduceLink();
          if (!needToReduceLink()) {
            this.setState((prevState: any) => ({
              ...this.state,
              landingLinksName: newLandingLinksName,
              hiddenLinksInMoreLink: [
                ...popped,
                ...prevState.hiddenLinksInMoreLink,
              ],
            }));
          }
        }
      } else if (hiddenLinksInMoreLink.length) {
        let loopCount = 0;
        while (needToAddLink() && loopCount < 10) {
          loopCount += 1;
          addLink();
          if (!needToAddLink()) {
            this.setState((prevState: any) => ({
              ...this.state,
              landingLinksName: [...newLandingLinksName, ...added],
              hiddenLinksInMoreLink: newHiddenLinksInMoreLink,
            }));
          }
        }
      }
    }
  };

  redirectsIfLoged = () => {
    if (
      (this.userLogged || userService.isUserLoggedIn())
      && ((this.props as any).location.includes('login')
        || (this.props as any).location.includes('register'))
    ) {
      Router.push(
        '/dashboard/profile',
        `/${(this.props as any).language}/dashboard/profile`,
      );
    }
  };

  getLinks = (): { [index: string]: any } => {
    const {
      language,
      location,
      windowWidth,
      isVisibleMobileMenu,
      logout,
      avatar,
      balance,
      moneyType,
    } = this.props;
    const { hiddenLinksInMoreLink, hiddenLinksInMoreLinkExpanded } = this.state;
    const buttonClass = 'Header-button';
    const parentClass = 'Header-button__wrapper';
    const subMenuClass = 'Header-button__sub-menu';
    const subMenuDashboardClass = 'Header-dashboard-button__sub-menu';
    const subMenuButtonClass = 'sub-menu__button';
    const subMenuButtonBorderBottomClass = 'sub-menu__button--border-bottom';
    const headerButtonWrapperClass = 'Header__button-wrapper';
    const currentLanguageInfo = this.getCurrentLangInfo(language);
    const cookies = new Cookies();

    const listLanguage = (): string[] => {
      if (
        !location.includes('dashboard')
        && (isPagePath(location, '/:lang/joint-participation')
          || isPagePath(location, '/:lang/joint-participation-binance')
          || location.includes('/pool/'))
      ) {
        return ['en', 'zh', 'ru'];
      }
      /* if (isPagePath(location, '/:lang/hosting') || isPagePath(location, '/:lang/data-center')) {
          return ['ru', 'zh'];
        } */
      return isLanding(location)
        ? ['en', 'zh', 'ru', 'it', 'es', 'pt', 'de', 'kr', 'ae', 'id']
        : ['en', 'ru'];
    };

    return {
      more: {
        text: this.getMenuButtonText(<Trans>Ещё</Trans>, true),
        tagName: Button,
        props: {
          ref: this.moreRef,
          className: buttonClass,
          color: 'inherit',
          onClick: () => this.handleToggleSubMenu('openedMoreMenu'),
        },
        parentProps: {
          className: `${parentClass} hide1024`,
          style: {
            position: hiddenLinksInMoreLink.length ? 'initial' : 'absolute',
            left: hiddenLinksInMoreLink.length ? 'initial' : '-9999px',
          },
        },
        refName: 'moreRef',
        stateName: 'openedMoreMenu',
        subMenu: {
          props: {
            className: subMenuClass,
          },
          links: hiddenLinksInMoreLinkExpanded,
        },
      },
      opportunities: {
        text: this.getMenuButtonText(
          windowWidth > 1024 ? (
            <Trans>Возможности</Trans>
          ) : (
            <Trans>МАЙНИНГ</Trans>
          ),
          windowWidth > 1024,
        ),
        tagName: windowWidth > 1024 ? Button : 'span',
        props: {
          ref: this.productsRef,
          className: buttonClass,
          color: 'inherit',
          onClick: () => this.handleToggleSubMenu('openedProductsMenu'),
        },
        parentProps: {
          className: `${parentClass} hide1024`,
        },
        refName: 'productsRef',
        stateName: 'openedProductsMenu',
        subMenu: {
          props: {
            className: subMenuClass,
          },
          links: [
            windowWidth <= 1024 ? 'joint-participation-binance' : null,
            'cloudmining',
            'miners',
            // process.env.PORT === '3001' ? null : 'lending',
            'affiliate-program',
            // 'hosting',
            'joint-participation',
          ],
        },
      },
      // hosting: {
      //   text: <Button itemProp="url" component="a" href={`${languageReturner(language)}/affiliate-program`} color="inherit" className={subMenuButtonClass}><Trans>Хостинг</Trans></Button>,
      //   tagName: Link,
      //   props: {
      //     href: '/hosting',
      //     as: `${languageReturner(language)}/hosting`,
      //   },
      // },
      'rent-data-center': {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/affiliate-program`}
            color="inherit"
            className={subMenuButtonClass}
          >
            <Trans>Аренда дата-центра</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/rent-data-center',
          as: `${languageReturner(language)}/rent-data-center`,
        },
      },
      'affiliate-program': {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/affiliate-program`}
            color="inherit"
            className={subMenuButtonClass}
          >
            <Trans>Партнерская программа</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/affiliate-program',
          as: `${languageReturner(language)}/affiliate-program`,
        },
      },
      'joint-participation': {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/joint-participation`}
            color="inherit"
            className={subMenuButtonClass}
          >
            <Trans>Совместные покупки</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/joint-participation',
          as: `${languageReturner(language)}/joint-participation`,
        },
      },
      cloudmining: {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/cloudmining`}
            color="inherit"
            className={subMenuButtonClass}
          >
            <Trans>Аренда хешрейта</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/cloudmining',
          as: `${languageReturner(language)}/cloudmining`,
        },
      },
      // lending: {
      //   text: <Button itemProp="url" component="a" href={`${languageReturner(language)}/lending`} color="inherit" className={subMenuButtonClass}><Trans>Вложения</Trans></Button>,
      //   tagName: Link,
      //   props: {
      //     href: '/lending',
      //     as: `${languageReturner(language)}/lending`,
      //   },
      // },
      miners: {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/miners`}
            color="inherit"
            className={subMenuButtonClass}
          >
            <Trans>Майнеры</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/miners',
          as: `${languageReturner(language)}/miners`,
        },
      },
      // "joint-participation-binance": {
      //   text: (
      //     <Button
      //       itemProp="url"
      //       component="a"
      //       href={
      //         process.env.SITE_SUBDOMAIN_URL
      //           ? `${process.env.SITE_SUBDOMAIN_URL}${languageReturner(
      //               language
      //             )}`
      //           : `${languageReturner(language)}/joint-participation-binance`
      //       }
      //       color="inherit"
      //       className={
      //         isPagePath(location, "/:lang/_mintoLanding")
      //           ? `joint-participation-minto-button ${buttonClass}`
      //           : `joint-participation-binance-button ${buttonClass}`
      //       }
      //     >
      //       <img src={binanceAndCULogos} />
      //     </Button>
      //   ),
      //   tagName: process.env.SITE_SUBDOMAIN_URL ? "span" : Link,
      //   props: {
      //     href: process.env.SITE_SUBDOMAIN_URL
      //       ? process.env.SITE_SUBDOMAIN_URL
      //       : "/joint-participation-binance",
      //     as: process.env.SITE_SUBDOMAIN_URL
      //       ? `${process.env.SITE_SUBDOMAIN_URL}${languageReturner(language)}`
      //       : `${languageReturner(language)}/joint-participation-binance`,
      //   },
      //   parentProps: {
      //     className: `${parentClass} hide768`,
      //   },
      // },
      'joint-participation-binance': {
        text: null,
        tagName: 'span',
        props: {
          href: '',
          as: '',
        },
        parentProps: {
          className: '',
        },
      },

      media: {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/miners`}
            color="inherit"
            className={subMenuButtonClass}
          >
            <Trans>СМИ о нас</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/media',
          as: `${languageReturner(language)}/media`,
        },
      },
      // 'joint-participation-binance': {
      //   text: <Button itemProp="url" component="a" href={`${languageReturner(language)}/news`} color="inherit" className={`joint-participation-binance-button ${buttonClass}`}><img src={binanceAndCULogos} /></Button>,
      //   tagName: Link,
      //   props: {
      //     href: `/joint-participation-binance`,
      //     as: `${languageReturner(language)}/joint-participation-binance`
      //   }
      // },
      news: {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/news`}
            color="inherit"
            className={buttonClass}
          >
            <Trans>Новости</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/news',
          as: `${languageReturner(language)}/news`,
        },
        parentProps: {
          className: 'hide1024',
        },
      },
      aboutCryptoUniverse: {
        text: this.getMenuButtonText(
          windowWidth > 1024 ? (
            <Trans>О CryptoUniverse</Trans>
          ) : (
            <Trans>О НАС</Trans>
          ),
          windowWidth > 1024,
        ),
        tagName: windowWidth > 1024 ? Button : 'span',
        props: {
          ref: this.aboutRef,
          className: `${buttonClass} hide1024`,
          color: 'inherit',
          onClick: () => (windowWidth > 1024
            ? this.handleToggleSubMenu('openedAboutMenu')
            : null),
        },
        parentProps: {
          className: parentClass,
        },
        subMenu: {
          props: {
            className: subMenuClass,
          },
          links: isVisibleMobileMenu
            ? ['news', 'about-us', 'data-center', 'principles']
            : ['about-us', 'data-center', 'principles'],
        },
        refName: 'aboutRef',
        stateName: 'openedAboutMenu',
      },
      'about-us': {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/about-us`}
            color="inherit"
            className={subMenuButtonClass}
          >
            <Trans>О компании</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/about-us',
          as: `${languageReturner(language)}/about-us`,
        },
      },
      'data-center': {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/data-center`}
            color="inherit"
            className={subMenuButtonClass}
          >
            <Trans>Майнинг центр</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/data-center',
          as: `${languageReturner(language)}/data-center`,
        },
      },
      principles: {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/principles`}
            color="inherit"
            className={subMenuButtonClass}
          >
            <Trans>Принцип работы</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/principles',
          as: `${languageReturner(language)}/principles`,
        },
      },
      cabinet: {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/dashboard`}
            color="inherit"
            className={buttonClass}
          >
            <Trans>Кабинет</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/dashboard',
          as: `${languageReturner(language)}/dashboard`,
        },
        parentProps: {
          className: 'hide1024',
          style: { marginLeft: 'auto' },
        },
      },
      landingLogout: {
        text: <Trans>Выйти</Trans>,
        tagName: Button,
        props: {
          itemProp: 'url',
          component: 'a',
          color: 'inherit',
          className: buttonClass,
          onClick: () => logout().then(() => {
            Router.push('/', `${languageReturner(language)}`);
          }),
        },
        parentProps: {
          className: 'hide1024',
        },
      },
      login: {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/login`}
            color="inherit"
            className={buttonClass}
          >
            <Trans>Войти</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/login',
          as: `${languageReturner(language)}/login`,
        },
        parentProps: {
          className: 'hide1024',
          style: { marginLeft: 'auto' },
        },
      },
      registration: {
        text: (
          <Button
            itemProp="url"
            component="a"
            href={`${languageReturner(language)}/register`}
            color="inherit"
            className={buttonClass}
          >
            <Trans>Регистрация</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/register',
          as: `${languageReturner(language)}/register`,
        },
        parentProps: {
          className: 'hide1024',
        },
      },
      profile: {
        text: this.getMenuButtonText(
          <>
            <div
              className="header-avatar"
              style={{ backgroundImage: `url(${avatar})` }}
            />
            <Trans>Мой профиль</Trans>
          </>,
          true,
        ),
        tagName: Button,
        props: {
          ref: this.profileRef,
          className: `${buttonClass} Header__button--left-border`,
          color: 'inherit',
          onClick: () => this.handleToggleSubMenu('openedProfileMenu'),
        },
        parentProps: {
          className: headerButtonWrapperClass,
        },
        subMenu: {
          props: {
            className: subMenuDashboardClass,
          },
          links: [
            'information',
            'wallets',
            'security',
            'help',
            'feedback',
            'logout',
          ],
        },
        refName: 'profileRef',
        stateName: 'openedProfileMenu',
      },
      information: {
        text: (
          <Button
            component="a"
            color="inherit"
            className={subMenuButtonClass}
            onClick={() => this.closeSubMenu('openedProfileMenu')}
          >
            <Trans>Информация</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/dashboard/profile',
          as: `${languageReturner(language)}/dashboard/profile`,
        },
      },
      wallets: {
        text: (
          <Button
            component="a"
            color="inherit"
            className={subMenuButtonClass}
            onClick={() => this.closeSubMenu('openedProfileMenu')}
          >
            <Trans>Кошельки</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/dashboard/profile/wallets',
          as: `${languageReturner(language)}/dashboard/profile/wallets`,
        },
      },
      security: {
        text: (
          <Button
            component="a"
            color="inherit"
            className={`${subMenuButtonClass} ${subMenuButtonBorderBottomClass}`}
            onClick={() => this.closeSubMenu('openedProfileMenu')}
          >
            <Trans>Безопасность</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/dashboard/profile/security',
          as: `${languageReturner(language)}/dashboard/profile/security`,
        },
      },
      help: {
        text: (
          <Button
            component="a"
            color="inherit"
            className={subMenuButtonClass}
            onClick={() => this.closeSubMenu('openedProfileMenu')}
          >
            <Trans>Помощь</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: '/dashboard/faq',
          as: `${languageReturner(language)}/dashboard/faq`,
        },
      },
      feedback: {
        text: (
          <Button
            component="a"
            color="inherit"
            className={`${subMenuButtonClass} ${subMenuButtonBorderBottomClass}`}
            onClick={() => {
              Router.push('https://cryptouniverse.freshdesk.com/support/tickets/new');
              this.closeSubMenu('openedProfileMenu');
            }}
          >
            <Trans>Обратная связь</Trans>
          </Button>
        ),
        tagName: Link,
        props: {
          href: `/${location.replace(`${languageReturner(language)}`, '')}`,
          as: `${languageReturner(language)}/${location}`, // ${languageReturner(language)}/dashboard/contact-us
        },
      },
      logout: {
        text: <Trans>Выйти</Trans>,
        tagName: Button,
        props: {
          component: 'a',
          color: 'inherit',
          className: subMenuButtonClass,
          onClick: () => logout().then(() => {
            this.closeSubMenu('openedProfileMenu');
            Router.push('/login', `${languageReturner(language)}/login`);
          }),
        },
      },
      currentLang: {
        text: this.getMenuButtonText(
          <>
            <img
              src={currentLanguageInfo!.icon}
              className="flag"
              alt="flag"
              width="23"
              height="15"
            />
            <span className="Header__lang-name">
              {currentLanguageInfo!.name}
            </span>
          </>,
          true,
        ),
        tagName: Button,
        props: {
          ref: this.langRef,
          className: `${buttonClass} Header__lang-button Header__button--left-border`,
          color: 'inherit',
          onClick: () => this.handleToggleSubMenu('openedLanguageMenu'),
        },
        parentProps: {
          className: `${parentClass} Header__lang-button-wrapper`,
        },
        subMenu: {
          props: {
            className: `${
              isLanding(location) ? subMenuClass : subMenuDashboardClass
            } language__sub-menu`,
          },
          links: listLanguage(),
        },
        refName: 'langRef',
        stateName: 'openedLanguageMenu',
      },
      baseMoney: {
        text: this.getMenuButtonText(moneyType || this.baseCurrency, true),
        tagName: Button,
        props: {
          ref: this.baseMoneyRef,
          className: `${buttonClass} header__base-money ${
            windowWidth >= 1024 && isLanding(location)
              ? 'Header__button--left-border'
              : ''
          }`,
          color: 'inherit',
          onClick: () => {
            this.handleToggleSubMenu('openedBaseMoney');
          },
        },
        parentProps: {
          className: `${parentClass} base_currency__container`,
        },
        subMenu: {
          props: {
            className: `${
              isLanding(location) ? subMenuClass : subMenuDashboardClass
            } submenu__money-type`,
          },
          links: [
            'US Dollar',
            'Euro',
            'British Pound',
            'Swiss Franc',
            'Japanese Yen',
            'Chinese Yuan',
          ],
        },
        refName: 'baseMoneyRef',
        stateName: 'openedBaseMoney',
      },
      border: {
        text: (
          <svg
            width="5"
            height="22"
            viewBox="0 0 5 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M3.64327 1L1 20.8246"
              stroke="#B0B0B0"
              strokeLinecap="square"
            />
          </svg>
        ),
        tagName: 'div',
        parentProps: {
          className: `${parentClass} base_currency__container border__slash`,
        },
      },
      ...(() => {
        const cashObj: { [index: string]: any } = {};
        [
          { name: 'Chinese Yuan', img: yen },
          { name: 'Japanese Yen', img: yen },
          { name: 'Swiss Franc', img: franc },
          { name: 'British Pound', img: pound },
          { name: 'US Dollar', img: dolar },
          { name: 'Euro', img: euro },
        ].map((cash) => {
          cashObj[cash.name] = {
            text: (
              <div className="Header__cash-type-containter">
                <div className="Header__cash-image">
                  <img src={cash.img} alt="cash.img" />
                </div>
                <span>{cash.name}</span>
              </div>
            ),
            tagName: Button,
            props: {
              key: `${cash.name}`,
              component: 'a',
              onClick: () => {
                (this.props as any).saveMoneType(cash.name);
                cookies.set('base_currency', cash.name, { path: '/' });
                this.handleToggleSubMenu('openedBaseMoney');
              },
            },
          };
        });
        return cashObj;
      })(),
      mobileMenu: {
        text: isVisibleMobileMenu ? <i className="f3 fal fa-times" /> : newMenu,
        tagName: Button,
        parentProps: {
          className:
            'Header__mobile-menu-button Header__button--left-border hide show1024',
        },
        props: {
          className: buttonClass,
          color: 'inherit',
          onClick: () => this.handleToggleMenu(),
        },
      },
      lkMenu: {
        text: newMenu,
        tagName: Button,
        parentProps: {
          className: 'Header__mobile-menu-button',
        },
        props: {
          className: buttonClass,
          color: 'inherit',
          onClick: () => (this.props as any).openMobileSidebar(),
        },
      },
      lkMobiLogo: {
        text: (
          <Button component="a" color="inherit" className={subMenuButtonClass}>
            <span>
              <Trans>CRYPTOUNIVERSE</Trans>
            </span>
          </Button>
        ),
        tagName: Link,
        parentProps: {
          className: 'Header__lk_menu_mobile-logo',
        },
        props: {
          href: '/',
          as: `${languageReturner(language)}/`,
        },
      },
      lkMobiAvatar: {
        text: (
          <div
            className="header-avatar"
            style={{ backgroundImage: `url(${avatar})` }}
          />
        ),
        parentProps: {
          className: 'Header__lk_menu_mobile-avatar',
        },
        tagName: Link,
        props: {
          href: `${languageReturner(language)}/dashboard/profile`,
          as: `${languageReturner(language)}/dashboard/profile`,
        },
      },
      ...(() => {
        const langsObj: { [index: string]: any } = {};
        this.getLangMenuList().map((langInfo) => {
          const currentLanguageInfo = this.getCurrentLangInfo(
            langInfo.langCode,
          );
          langsObj[langInfo.langCode] = {
            text: (
              <>
                <img
                  src={currentLanguageInfo!.icon}
                  className="flag"
                  alt="flag"
                  width="23"
                  height="15"
                />
                <span className="Header__lang-name">
                  {currentLanguageInfo!.name}
                </span>
              </>
            ),
            tagName: Button,
            props: {
              key: `${langInfo.name}`,
              component: 'a',
              className: subMenuButtonClass,
              itemProp: 'url',
              color: 'inherit',
              href: `/${langInfo.langCode}`,
              onClick: (e: any) => {
                e.preventDefault();
                changeLanguage(currentLanguageInfo!.langCode);
                this.setState({
                  ...this.state,
                  currentLangInfo: currentLanguageInfo!.langCode,
                });
                this.handleCloseTimeout(e, 'langRef', 'openedLanguageMenu');
              },
            },
          };
        });
        return langsObj;
      })(),
    };
  };

  handleToggleMenu = () => {
    const {
      isVisibleMobileMenu,
      showMobileNavigationMenu,
      hideMobileNavigationMenu,
    } = this.props;
    isVisibleMobileMenu
      ? hideMobileNavigationMenu()
      : showMobileNavigationMenu();
  };

  handleCloseTimeout = (event: any, anchorName: any, stateName: any) => {
    event.persist();
    setTimeout(() => {
      this.eventCloseSubMenu(event, anchorName, stateName);
    }, 0);
  };

  getCurrentLangInfo = (
    lng: any,
  ):
    | { name?: any; icon?: any; langCode?: any }
    | undefined
    | { [index: string]: any } => (lng
    ? this.getLangMenuList().find((item) => item.langCode === lng)
    : this.getLangMenuList().find((item) => item.langCode === 'en'));

  getLangMenuList = () => {
    const { windowWidth } = this.props;
    return [
      {
        name: windowWidth > 767 ? 'English' : 'Eng',
        icon: unitedkingdom,
        langCode: 'en',
      },
      {
        name: '中文',
        icon: china,
        langCode: 'zh',
      },
      {
        name: windowWidth > 767 ? 'Русский' : 'Рус',
        icon: russia,
        langCode: 'ru',
      },
      {
        name: windowWidth > 767 ? 'Italiano' : 'Ita',
        icon: italy,
        langCode: 'it',
      },
      {
        name: windowWidth > 767 ? 'Español' : 'Esp',
        icon: spain,
        langCode: 'es',
      },
      {
        name: windowWidth > 767 ? 'Português' : 'Por',
        icon: portugal,
        langCode: 'pt',
      },
      {
        name: windowWidth > 767 ? 'Deutsche' : 'Deu',
        icon: german,
        langCode: 'de',
      },
      {
        name: '한국어',
        icon: korea,
        langCode: 'kr',
      },
      {
        name: 'العَرَبِيَّة',
        icon: uae,
        langCode: 'ae',
      },
      {
        name: windowWidth > 767 ? 'Bahasa' : 'Bah',
        icon: indonesia,
        langCode: 'id',
      },
    ];
  };

  getHeaderMenu = (links: any) => {
    const getLinks = this.getLinks();
    return Object.values(links).reduce((acc, link: any) => {
      if (link !== null) (acc as any)[link] = getLinks[link];
      return acc;
    }, {});
  };

  closeSubMenu = (stateName: any) => {
    if (this.state[stateName]) {
      this.setState({
        ...this.state,
        [stateName]: false,
      });
    }
  };

  eventCloseSubMenu = (event: any, anchorName: string, stateName: any) => {
    if (
      (this as any)[anchorName].current
      && (this as any)[anchorName].current.contains(event.target)
    ) {
      return true;
    }
    this.closeSubMenu(stateName);
  };

  handleToggleSubMenu = (stateName: any) => {
    this.setState({
      ...this.state,
      [stateName]: !this.state[stateName],
    });
  };

  generateMenuItems = (items: any) => {
    const getLinks = this.getLinks();
    return (
      Object.keys(items).length > 0
      && Object.keys(items).map((item, i) => {
        const {
          parentProps,
          tagName,
          props,
          text,
          subMenu = {},
          refName,
          stateName,
        } = items[item];
        const { isVisibleMobileMenu } = this.props;
        const Btn = tagName;
        let topLevelName = Object.entries(subMenu).length
          ? {}
          : { itemProp: 'name' };
        return (
          <div
            {...topLevelName}
            key={item}
            data-name={item}
            {...parentProps}
            suppressHydrationWarning
          >
            <Btn {...props}>{text}</Btn>
            {Object.entries(subMenu).length ? (
              <ClickAwayListener
                onClickAway={(e) => this.eventCloseSubMenu(e, refName, stateName)}
              >
                <div
                  className={`${subMenu.props.className} ${
                    this.state[stateName] ? 'opened' : 'closed'
                  }`}
                >
                  {subMenu.links.map((link: any, j: any) => {
                    if (link === null) return null;
                    const { tagName, text, props } = getLinks[link];
                    const SubMenuBtn = tagName;
                    const SubMenuBtnOnClick = props.onClick
                      ? { onClick: (e: any) => props.onClick(e) }
                      : {};
                    return (
                      <div itemProp="name" data-name={link} key={`${i}${j}`}>
                        <SubMenuBtn {...props} {...SubMenuBtnOnClick}>
                          {text}
                        </SubMenuBtn>
                      </div>
                    );
                  })}
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
        );
      })
    );
  };

  getMenuButtonText = (title: any, nested: boolean | null = null) => (
    <>
      {title}
      {nested && <i className="fal fa-angle-down ml2" />}
    </>
  );

  getHeaderClasses = () => {
    const { location } = this.props;
    const { scrollY } = this.state;
    const classes = ['Header'];
    if (isPagePath(location, '/:lang')) {
      classes.push('Header--white');
    } else if (
      isPagePath(location, '/:lang/binance')
      || isPagePath(location, ['/:lang/joint-participation-binance'])
    ) {
      classes.push('Header--dark');
    } else if (isPagePath(location, '/:lang/minto')) {
      classes.push('Header--geenDark');
    }
    // if (location.includes('dashboard') || location.includes('login') || location.includes('register') || location.includes('forgot-password') || location.includes('change-password')) {
    //   classes.push('Header--blue');
    // }
    // if (isPagePath(location, '/:lang/hosting') || isPagePath(location, '/:lang/data-center')) {
    //   classes.push('Header--transparent');
    // }
    // // else if(isPagePath(location, '/:lang/binance') || isPagePath(location, ['/:lang/joint-participation-binance'])) {
    // //   classes.push('Header--dark')
    // // }
    else if (isPagePath(location, '/:lang/contacts')) {
      classes.push('Header--blue'); // Header--transparent
    } else {
      classes.push('Header--blue');
    }

    if (isDashboard(location)) classes.push('Header--dashboard');

    return classes.join(' ');
  };

  render() {
    const {
      location,
      isVisibleMobileMenu,
      windowWidth,
      language,
      isUserLoggedIn,
    } = this.props;
    const { landingLinksName } = this.state;
    return (
      <div
        className="Header_container"
        style={{ position: 'fixed', width: '100%', zIndex: 999 }}
      >
        <div
          className={`${
            isVisibleMobileMenu ? 'open' : ''
          } ${this.getHeaderClasses()}`}
          ref={this.transparentHeader}
        >
          {isLanding(location) && (
            <div className="Header__logo-container">
              <Link
                href={`${process.env.SITE_URL}`}
                as={`${process.env.SITE_URL}${languageReturner(language)}`}
              >
                <a className="Header__logo-link">
                  {false && (
                    <>
                      {/* Новое лого на новых страницах */}
                      <img
                        src={
                          this.getHeaderClasses().includes('Header--blue')
                          || (this.getHeaderClasses().includes('white')
                            && this.getHeaderClasses().includes(
                              'Header--transparent',
                            ))
                            ? whiteLogo
                            : newLogo
                        }
                        className="Header__logo"
                        alt="header-logo"
                        width="220"
                        height="64"
                      />
                    </>
                  )}
                  <img
                    src={isPagePath(location, '/:lang') ? grayLogo : whiteLogo}
                    className="Header__logo"
                    alt="header-logo"
                    width="220"
                    height="64"
                  />
                </a>
              </Link>
            </div>
          )}
          <div
            ref={this.menuRef}
            suppressHydrationWarning
            className={'Header__menu'.concat(
              isLanding(location) ? '' : ' lk_header_menu',
            )}
            itemScope
            itemType="http://www.schema.org/SiteNavigationElement"
          >
            {this.generateMenuItems(
              isLanding(location)
                ? this.getHeaderMenu([
                  ...landingLinksName,
                  'more',
                  this.userLogged || isUserLoggedIn ? 'cabinet' : null,
                  this.userLogged || isUserLoggedIn ? 'landingLogout' : null,
                  !(this.userLogged || isUserLoggedIn) ? 'login' : null,
                  !(this.userLogged || isUserLoggedIn)
                    ? 'registration'
                    : null,
                  'currentLang',
                  'mobileMenu',
                ])
                : this.getHeaderMenu(
                  (process as any).browser
                      && (windowWidth > 1279
                        ? ['currentLang', 'border', 'baseMoney', 'profile']
                        : [
                          'lkMenu',
                          'lkMobiLogo',
                          windowWidth > 767 ? 'currentLang' : null,
                          windowWidth > 767 ? 'border' : null,
                          windowWidth > 767 ? 'baseMoney' : null,
                          windowWidth > 767 ? 'profile' : 'lkMobiAvatar',
                        ]),
                ),
            )}
          </div>
          <div
            suppressHydrationWarning
            className={`Header__mobile-menu ${
              isVisibleMobileMenu ? 'open' : ''
            }`}
          >
            {isLanding(location) && process.browser ? (
              <>
                <div className="Header__mobile-menu--top">
                  {windowWidth <= 1024
                    && this.generateMenuItems(
                      this.getHeaderMenu([
                        this.userLogged || isUserLoggedIn ? 'cabinet' : null,
                        this.userLogged || isUserLoggedIn
                          ? 'landingLogout'
                          : null,
                        !(this.userLogged || isUserLoggedIn) ? 'login' : null,
                        !(this.userLogged || isUserLoggedIn)
                          ? 'registration'
                          : null,
                        // windowWidth <= 768 ? 'currentLang' : null,
                      ]),
                    )}
                </div>
                <div className="Header__mobile-menu--body">
                  {windowWidth <= 1024
                    && this.generateMenuItems(
                      this.getHeaderMenu([
                        'opportunities',
                        'aboutCryptoUniverse',
                      ]),
                    )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const {
    location,
    isVisibleMobileMenu,
    windowWidth,
    language,
    coinRates,
    moneyType,
  } = state.info;
  const { avatar, balance, isUserLoggedIn } = state.user;
  return {
    isVisibleMobileMenu,
    windowWidth,
    location,
    language,
    avatar,
    balance,
    coinRates,
    isUserLoggedIn,
    moneyType,
  };
}
function mapDispatchToProps(dispatch: any) {
  return {
    hideMobileNavigationMenu: () => dispatch(infoActions.hideMobileNavigationMenu()),
    showMobileNavigationMenu: () => dispatch(infoActions.showMobileNavigationMenu()),
    openMobileSidebar: () => dispatch(infoActions.openMobileSidebar()),
    logout: () => dispatch(userActions.logout()),
    saveMoneType: (value: any) => dispatch(infoActions.saveMoneType(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderNew);
