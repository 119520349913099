import React, { Component } from 'react';
import Link from 'next/link';
import { connect } from 'react-redux';
import {
  Drawer, List, ListItem, Divider, NoSsr, Select, MenuItem, withStyles, FormControl, Button,
} from '@material-ui/core';
import './Sidebar.scss';
import './mobile/index.scss';
import {
  grayLogo, russia, unitedkingdom, dolar, euro, franc, pound, yen, joint_star,
} from '@assets/img';
import { infoActions, userActions } from '@redux/actions';
import { infoConstants } from '@redux/constants';
import Router from 'next/router';
import Cookies from 'universal-cookie';
import { Trans } from '@i18n';
import { userService } from '../../services';
import { BootstrapInput, BetaVersion, Polygon } from '../index';
import { isLanding, languageReturner } from '../../helpers';
import { changeLanguage } from '../../locales/i18n';

const styles = (theme: any) => ({
  margin: {
    margin: theme.spacing(0),
  },
});
const joint_icon = (
  <Polygon
    cornerRadius={5}
    cornerPosition="rightBottom"
    bgColor="#FFE27A"
    boxShadow={{
      x: 0, y: 2, blurRadius: 22, color: 'rgba(74, 74, 74, 0.0908818)',
    }}
  >
    <div className="joint-icon__inner">
      <img src={joint_star} alt="joint_star" />
      <Trans>new</Trans>
    </div>
  </Polygon>
);
type SidebarProps = {
    location?: string;
    language?: string;
    windowWidth?: any;
    newOperation?: any;
    referalLengthNew?: any;
    referalLength?: any;
    accrualsLengthNew?: any;
    accrualsLength?: any;
    hideMobileSidebar?: any;
    avatar?: 'string';
    saveMoneType?: any;
    moneyType?: 'string';
    logout?: any;
    style?: any;
};
type SidebarState = any;
class Sidebar extends Component<SidebarProps, SidebarState> {
  constructor(props: SidebarProps) {
    super(props);
    this.state = {
      open: (props as any).openedMobileSidebar,
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
  }

  toggleDrawer() {
    return (event: any) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      this.setState({ open: (this.props as any).openedMobileSidebar });
    };
  }

    handleChangeLanguage = (event: any) => {
      changeLanguage(event.target.value);
      (this.props as any).changeLanguage(event.target.value);
    };

    handleChangeMoney = (event: any) => {
      (this.props as any).saveMoneType(event.target.value);
    };

    getLangMenuList = () => [
      {
        name: 'English',
        icon: unitedkingdom,
        langCode: 'en',
      },
      {
        name: 'Русский',
        icon: russia,
        langCode: 'ru',
      },
    ];

    getNotificationBadge = (badgeName: any) => {
      const {
        newOperation, referalLengthNew, referalLength, accrualsLengthNew, accrualsLength,
      } = this.props;
      const notifications:{[index:string]:any} = {
        newOperation: {
          count: newOperation >= 4 ? '4+' : newOperation,
          showCondition: newOperation && Math.sign(newOperation) !== -1,
        },
        referalLengthNew: {
          count: referalLengthNew - referalLength,
          showCondition: ((referalLengthNew !== null && referalLength !== null) && Math.sign(referalLengthNew - referalLength) !== -1 && (referalLengthNew - referalLength !== 0 && !isNaN(referalLengthNew - referalLength))),
        },
        accrualsLengthNew: {
          count: accrualsLengthNew - accrualsLength,
          showCondition: (accrualsLengthNew !== null && accrualsLength !== null) && Math.sign(accrualsLengthNew - accrualsLength) !== -1 && (accrualsLengthNew - accrualsLength !== 0),
        },
      };
      if (!notifications[badgeName].showCondition) return null;
      return <span className="sidebar-badge sidebar-badge--red">{notifications[badgeName].count}</span>;
    };

    render() {
      const {
        location, language, windowWidth, hideMobileSidebar, avatar, saveMoneType, moneyType, logout, accrualsLengthNew, accrualsLength, referalLengthNew, referalLength,
      } = this.props;
      const cookies = new Cookies();
      if (isLanding(location)) return null;
      return (
        <NoSsr>
          <Drawer
            className="drawer"
            variant="persistent"
            classes={{
              paper: 'drawerPaper',
            }}
            anchor="left"
            open={this.state.open}
            onClose={this.toggleDrawer()}
          >
            <div className="sidebar-wrapper">
              <div className="sidebar-logo">
                <Link href="/dashboard" as={`${languageReturner(language)}/dashboard`}>
                  <a>
                    <img src={grayLogo} alt="graylogo" />
                  </a>
                </Link>
                {(() => {
                  if (windowWidth < 1280) {
                    const closeIcon = (
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => hideMobileSidebar()}>
                        <path d="M3.12536 3.125L26.8754 26.875" stroke="#272727" strokeWidth="2" />
                        <path d="M26.8759 3.12498L3.12586 26.875" stroke="#272727" strokeWidth="2" />
                      </svg>
                    );
                    if (windowWidth >= 1024) {
                      return (
                        <div
                          className="close__icon-mobile-sidebar"
                          onClick={() => {
                            if (windowWidth < 768) {
                              hideMobileSidebar();
                            }
                          }}
                        >
                          {closeIcon}
                        </div>
                      );
                    }
                    return (
                      <Button>
                        <div
                          className="close__icon-mobile-sidebar"
                          onClick={() => {
                            if (windowWidth < 768) {
                              hideMobileSidebar();
                            }
                          }}
                        >
                          {closeIcon}
                        </div>
                      </Button>
                    );
                  }
                  return null;
                })()}
              </div>
              {windowWidth < 768 && (
              <div className="sidebar__mobile-nav-menu">
                <div className="sidebar__mobile-nav-menu-first">
                  <Link href="/dashboard/profile" as={`${languageReturner(language)}/dashboard/profile`}>
                    <div className="header-avatar" style={{ backgroundImage: `url(${avatar})` }} />
                  </Link>
                  <Button>
                    <FormControl className="sidebar-mobi__location-changer">
                      <Select labelId="demo-customized-select-label" id="demo-customized-select" value="Мой профиль" input={<BootstrapInput />}>
                        {[
                          { name: 'Информация', href: '/dashboard/profile', as: `${languageReturner(language)}/dashboard/profile` },
                          { name: 'Кошельки', href: '/dashboard/profile/wallets', as: `${languageReturner(language)}/dashboard/profile/wallets` },
                          { name: 'Безопасность', href: '/dashboard/profile/security', as: `${languageReturner(language)}/dashboard/profile/security` },
                          { name: 'Помощь', href: '/dashboard/faq', as: `${languageReturner(language)}/dashboard/faq` },
                          { name: 'Обратная связь', href: 'https://cryptouniverse.freshdesk.com/support/tickets/new' },
                          { name: 'Выйти' },
                        ].map((menuUnit) => (menuUnit.href !== undefined
                          ? (
                            <MenuItem value={menuUnit.name} key={menuUnit.name} onClick={() => { Router.push(menuUnit.href, menuUnit.as); hideMobileSidebar(); }}>
                              <Trans>{menuUnit.name}</Trans>
                            </MenuItem>
                          ) : (
                            <MenuItem value={menuUnit.name} key={menuUnit.name} onClick={() => logout().then(() => Router.push('/login', `${languageReturner(language)}/login`))}>
                              <Trans>{menuUnit.name}</Trans>
                            </MenuItem>
                          )))}
                        <MenuItem value="Мой профиль" className="hidden">
                          <Trans>Мой профиль</Trans>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Button>
                </div>
                <div className="sidebar__mobile-nav-menu-second">
                  <Button>
                    <FormControl className="sidebar-mobi__language-changer">
                      <Select labelId="demo-customized-select-label" id="demo-customized-select" value={language} onChange={this.handleChangeLanguage} input={<BootstrapInput />}>
                        {this.getLangMenuList().map((lang) => (
                          <MenuItem value={lang.langCode} key={lang.langCode}>
                            <img src={lang.icon} alt="lang-icon" />
                            <span className="lang__text">{lang.name}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Button>
                  <svg width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M3.64327 1L1 20.8246" stroke="#B0B0B0" strokeLinecap="square" />
                  </svg>
                  <Button>
                    <FormControl className="sidebar-mobi__cash-changer">
                      <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={moneyType}
                        onChange={(event) => {
                          saveMoneType(event.target.value);
                          cookies.set('base_currency', event.target.value, { path: '/' });
                        }}
                        input={<BootstrapInput />}
                      >
                        {[
                          { name: 'Chinese Yuan', img: yen },
                          { name: 'Japanese Yen', img: yen },
                          { name: 'Swiss Franc', img: franc },
                          { name: 'British Pound', img: pound },
                          { name: 'US Dollar', img: dolar },
                          { name: 'Euro', img: euro },
                        ].map((money) => (
                          <MenuItem value={money.name} key={money.name}>
                            <div className="Header__cash-type-containter">
                              <div className="Header__cash-image"><img src={money.img} alt="cash.img" /></div>
                              <span>
                                {((value) => {
                                  switch (value) {
                                    case 'Chinese Yuan': return 'Yuan';
                                    case 'Japanese Yen': return 'Yen';
                                    case 'Swiss Franc': return 'Franc';
                                    case 'British Pound': return 'Pound';
                                    case 'US Dollar': return 'USD';
                                    case 'Euro': return 'Euro';
                                  }
                                })(money.name)}
                              </span>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Button>
                </div>
              </div>
              )}
              <div className="sidebar-list">
                <div className="sidebar-label"><Trans>Управлять</Trans></div>
                <List>
                  {[
                    {
                      text: <div className="sidebar-list-item"><Trans>Панель управления</Trans></div>,
                      link: '/dashboard',
                      as: `${languageReturner(language)}/dashboard`,
                    },
                    {
                      text: <div className="sidebar-list-item">
                        <Trans>Балансы</Trans>
                        {this.getNotificationBadge('newOperation')}
                      </div>,
                      link: '/dashboard/balances',
                      as: `${languageReturner(language)}/dashboard/balances`,
                    },
                    {
                      text: <div className="sidebar-list-item"><Trans>Активы</Trans></div>,
                      link: '/dashboard/assets',
                      as: `${languageReturner(language)}/dashboard/assets`,
                    },
                    {
                      text: <div className="sidebar-list-item">
                        <Trans>Партнерская программа</Trans>
                        {this.getNotificationBadge('referalLengthNew')}
                      </div>,
                      link: '/dashboard/affiliate-program',
                      as: `${languageReturner(language)}/dashboard/affiliate-program`,
                    },
                  ].map((item) => (
                    <Link href={item.link} as={item.as} key={item.link}>
                      <ListItem
                        className={(item.link === location) ? 'active' : ''}
                        color="black"
                        button
                        onClick={() => {
                          if (windowWidth < 768) {
                            hideMobileSidebar();
                          }
                        }}
                      >
                        {item.text}
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </div>
              <div className="sidebar-list">
                <div className="sidebar-label"><Trans>Купить</Trans></div>
                <List>
                  {[
                    {
                      text: <div className="sidebar-list-item"><Trans>Аренда хeшрейта</Trans></div>,
                      link: '/dashboard/cloudmining',
                      as: `${languageReturner(language)}/dashboard/cloudmining`,
                    },
                    {
                      text: <div className="sidebar-list-item"><Trans>Купить майнер</Trans></div>,
                      link: '/dashboard/miners',
                      as: `${languageReturner(language)}/dashboard/miners`,
                    },
                    {
                      text: <div className="sidebar-list-item"><Trans>Совместные покупки</Trans>{joint_icon}</div>,
                      link: '/dashboard/joint-participation',
                      as: `${languageReturner(language)}/dashboard/joint-participation`
                    }
                  ].map((item) => (
                    <Link href={item.link} as={item.as} key={item.link}>
                      <ListItem
                        className={(item.link === location) ? 'active' : ''}
                        color="black"
                        button
                        onClick={() => {
                          if (windowWidth < 768) {
                            hideMobileSidebar();
                          }
                        }}
                      >
                        {item.text}
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </div>
              <div className="sidebar-list sidebar-list--bottom">
                <Divider />
                <List>
                  {[
                    {
                      text: <div className="sidebar-list-item"><Trans>Помощь</Trans></div>,
                      link: '/dashboard/faq',
                      as: `${languageReturner(language)}/dashboard/faq`,
                    },
                    {
                      text: <div className="sidebar-list-item"><Trans>Обратная связь</Trans></div>,
                      link: 'https://cryptouniverse.freshdesk.com/support/tickets/new',
                    },
                  ].map((item) => (
                    <Link href={item.link} as={item.as} key={item.link}>
                      <ListItem
                        className={(item.link === location) ? 'active' : ''}
                        color="black"
                        button
                        onClick={() => {
                          if (windowWidth < 768) {
                            hideMobileSidebar();
                          }
                        }}
                      >
                        {item.text}
                      </ListItem>
                    </Link>
                  ))}
                  <ListItem color="black" button onClick={() => { userService.logout(); Router.push('/login', `${languageReturner(language)}/login`); }}>
                    <div className="sidebar-list-item"><Trans>Выйти</Trans></div>
                  </ListItem>
                  <ListItem color="black" className="sidebar__on-langing-link" button onClick={() => { Router.push('/', `${languageReturner(language)}`); }}>
                    <div className="sidebar-list-item"><Trans>Перейти на основной сайт</Trans></div>
                  </ListItem>
                </List>
              </div>
            </div>
          </Drawer>
        </NoSsr>
      );
    }
}
function mapStateToProps(state: any) {
  const {
    location, language, windowWidth, openedMobileSidebar, moneyType,
  } = state.info;
  const {
    avatar, accrualsLength, accrualsLengthNew, referalLengthNew, referalLength, newOperation,
  } = state.user;
  return {
    location,
    language,
    windowWidth,
    openedMobileSidebar,
    avatar,
    moneyType,
    accrualsLength,
    accrualsLengthNew,
    referalLengthNew,
    referalLength,
    newOperation,
  };
}
function mapDispatchToProps(dispatch: any) {
  return {
    changeLanguage: (language: any) => dispatch({ type: infoConstants.CHANGE_LANGUAGE, language }),
    hideMobileSidebar: () => dispatch(infoActions.closeMobileSidebar()),
    logout: () => dispatch(userActions.logout()),
    saveMoneType: (value: any) => dispatch(infoActions.saveMoneType(value)),
  };
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
