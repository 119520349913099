import React from 'react';
import './CookiePanel.scss';
import './mobile/index.scss';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import Cookies from 'universal-cookie';
import { Trans } from '@i18n';
import { isLanding, languageReturner } from '../../helpers';
import { Btn } from '../index';

function CookiePanel() {
  const { language, location, windowWidth } = useSelector((state: any) => state.info);
  const mobileMessage = 'Мы используем cookie-файлы для сбора статистики, сохранения ваших настроек и улучшения работы сайта.';
  if (!isLanding(location)) return null;
  return (
    <div>
      <div className="cookies_block">
        <div className="cookies_block_text">
          {windowWidth < 768 ? (<Trans>{mobileMessage}</Trans>) : (
            <Trans>
              {mobileMessage}
              Например, чтобы вы могли переходить со страницы на страницу и при этом не входить заново в свою учетную запись каждый раз.
            </Trans>
          )}
          <Link href={`${languageReturner(language)}/cookie-policy`}><a><Trans>Подробнее...</Trans></a></Link>
        </div>
        <Btn
          flex
          color="white"
          className="cookies_block_button"
          onClick={() => {
            const cookies = new Cookies();
            cookies.set('cookieAccept', 'true', { path: '/' });
            (document.querySelector('.cookies_block') as any).style.display = 'none';
          }}
        >
          <div className="cookies_block_button-text">
            <Trans>ОK</Trans>
            <i className="fa fa-caret-right ml2" />
          </div>
        </Btn>
      </div>
    </div>
  );
}
export default CookiePanel;
